<template>
    <div class="page-header-tabs-component">
        <el-tabs v-model="active" @tab-click="gotoPage">
            <template v-for="tab in list">
                <el-tab-pane :label="tab.name" :name="tab.key" :params="tab" :key="tab.key" v-if="$checkPermission(tab.permission)" @click="gotoPage(tab)"></el-tab-pane>
            </template>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    data(){
        return {
            list: [],
            active: '',
        }
    },
    computed: {
        ...mapState({
            refreshHeader: state => state.common.refreshHeader,
        }),
        ...mapGetters({
            userInfo: 'user/userInfo',
        })

    },
    methods: {
        initList(){
            let tabs = [];
            let paths = this.$route.path.split('/');
            /* if(paths[1] === 'project'){
                this.active = paths[2];
                tabs = [
                    {name:'项目',key:'index',path:'/project/index', permission: 'project:projectIndexRouter'},
                    {name:'任务',key:'tasks',path:'/project/tasks', permission: 'project:tasksRouter'},
                ];
            } */
            if(paths.length > 3 && paths[3] === 'index'){
                this.active = paths[2];
                if(paths[1] === 'asset'){
                    tabs = [
                        {name: this.$t('component.PageHeaderTabs_tab_memory'),key:'memory',path:'/asset/memory/index', permission: 'memory:memoryStoreRouter'},
                        {name: this.$t('component.PageHeaderTabs_tab_term'),key:'term',path:'/asset/term/index', permission: 'memory:termStoreRouter'},
                    ]
                }else if(paths[1] === 'tools'){
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_polish'),key:'polish',path:'/tools/polish/index', permission: 'tools:polishRouter'});
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_translationScore'),key:'translationScore',path:'/tools/translationScore/index', permission: ''});
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_align'),key:'align',path:'/tools/align/index', permission: 'tools:alignmentRouter'});
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_termExtraction'),key:'termExtraction',path:'/tools/termExtraction/index', permission: 'tools:extractionRouter'});

                    if (['pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1){
                        tabs.push({name: this.$t('component.PageHeaderTabs_tab_qa'),key:'qa',path:'/tools/qa/index', permission: 'tools:checkRouter'});
                        tabs.push({name: this.$t('component.PageHeaderTabs_tab_detector'),key:'detector',path:'/tools/detector/index', permission: 'tools:detectorRouter'});
                        // tabs.push({name: this.$t('component.PageHeaderTabs_tab_videoTranslate'),key:'videoTranslate',path:'/tools/videoTranslate/index', permission: 'tools:videoTranslateRouter'});
                    }
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_videoTranslate'),key:'videoTranslate',path:'/tools/videoTranslate/index', permission: 'tools:videoTranslateRouter'});

                    if(['pro-trial', 'pro'].indexOf(this.userInfo.accountType) > -1){
                        tabs.push({name: this.$t('component.PageHeaderTabs_tab_detector'),key:'detector',path:'/tools/detector/index', permission: 'tools:detectorRouter'});
                        // tabs.push({name: this.$t('component.PageHeaderTabs_tab_videoTranslate'),key:'videoTranslate',path:'/tools/videoTranslate/index', permission: 'tools:videoTranslateRouter'});
                    }
                }else if(paths[1] === 'agent'){
                    tabs.push({name: this.$t('component.PageHeaderTabs_tab_gptTranslate'),key:'gptTranslate',path:'/agent/gptTranslate/index', permission: ''});
                }
            }
            if(paths[1] === 'engine' && ['quickCreate', 'ragCreate', 'create'].indexOf(paths[2]) > -1){
                this.active = paths[2];
                tabs.push({name: this.$t('component.PageHeaderTabs_tab_ragCreate'),key:'ragCreate',path:'/engine/ragCreate', permission: 'engine:engineQuickCreateRouter'});
                // tabs.push({name:'快速训练',key:'quickCreate',path:'/engine/quickCreate', permission: 'engine:engineQuickCreateRouter'});
                tabs.push({name: this.$t('component.PageHeaderTabs_tab_engineCreate'),key:'create',path:'/engine/create', permission: 'engine:engineCreateRouter'});
            }
            // console.log(tabs, paths)
            this.list = tabs;
        },
        gotoPage(tab, event){
            let params = tab.$attrs.params;
            this.$router.push(params.path);
        },
    },
    mounted() {
        this.initList();
    },
    watch: {
        '$store.state.common.refreshHeader'(to, from) {
            this.initList();
        },
        '$route'(to, from){
            this.initList();
        }
    },
}
</script>
<style lang="scss" scoped>
.page-header-tabs-component{
    height: 100%;
    width: 100%;
    ::v-deep .el-tabs {
        height: 100%;
        .el-tabs__nav-next, .el-tabs__nav-prev {
            line-height: 46px;
        }
        .el-tabs__header{
            margin-bottom: 0;
            height: 100%;
        }
        .el-tabs__nav-wrap {
            height: 100%;
            &::after {
                height: 0;
            }
        }
        .el-tabs__nav-scroll{
            height: 100%;
        }
        .el-tabs__nav {
            .el-tabs__active-bar {
                display: none;
            }
            height: 100%;
            .el-tabs__item {
                height: 100%;
                line-height: 46px;
                color: #2C2C2E;
                font-size: 15px;
                &.is-active {
                    font-weight: 600;
                    &::after {
                        content: "";
                        height: 2px;
                        background-color: #000;
                        position: absolute;
                        width: 20px;
                        bottom: 0;
                        left: calc(50% - 10px);
                        border-radius: 20px;
                    }
                }
            }
            .el-tabs__item:nth-child(2){
                &.is-active {
                    &::after {
                        left: calc(50% - 20px);
                    }
                }
            }
            .el-tabs__item:last-child{
                &.is-active {
                    &::after {
                        left: 50%;
                    }
                }
            }
        }
    }
    
    
}
</style>
