<template>
    <div>
        <template v-if="isLogin">
            <pay-pro-dialog></pay-pro-dialog>
            <pay-erp-dialog></pay-erp-dialog>
            <pay-chars-dialog></pay-chars-dialog>
            <pay-token-dialog></pay-token-dialog>
            <download-notices></download-notices>
            <bind-wechat></bind-wechat>
            <!-- <faq /> -->
        </template>
        <template v-else>
            <!-- <div class="absolute top-20 right-20 zindex-10">
                <change-client-language />
            </div> -->
        </template>
        
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PayProDialog from '@/components/PayProDialog';
import PayErpDialog from '@/components/PayErpDialog';
import PayCharsDialog from '@/components/PayCharsDialog';
import PayTokenDialog from '@/components/PayTokenDialog';
import DownloadNotices from '@/components/DownloadNotices.vue';
import BindWechat from '@/components/BindWechat';
import ChangeClientLanguage from '@/components/ChangeClientLanguageShow';
import Faq from '@/components/Faq';
export default {
    name: 'CommonComponent',
    components: {
        PayProDialog,
        PayErpDialog,
        PayCharsDialog,
        PayTokenDialog,
        DownloadNotices,
        BindWechat,
        Faq,
        ChangeClientLanguage
    },
    data(){
        return {
            // isLogin: false,
        }
    },
    computed: {
        ...mapGetters({
            isLogin:'user/loginStatus',
        }),
    },
    methods: {

    },
    created() {
    //    this.isLogin = localStorage.getItem('userInfo') && this.$cookies.get('token');
    },
    mounted() {

    },
    watch: {

    }

}
</script>
