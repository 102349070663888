import md5 from 'md5';
import $ from 'jquery';
import { set } from 'lodash';

const formatTime=(time)=>{
    if(time===0)return "00:00";
    if(!time)return null;
    let _time=Math.round(time);
    // console.log(typeof _time,_time);
    let rst="";
    let min=parseInt(_time/60);
    if(min<10){
        rst+='0'+min
    }else{
        rst+=min;
    }
    rst+=':'
    let sec=_time%60;
    if(sec<10){
        rst+='0'+sec;
    }else{
        rst+=sec
    }
    return rst;
};
const strSplitAfterSome=(str,places)=>{
    if(str.length>=places){
        return str.substring(str.length-places,str.length);
    }
    return str;
}
/**
 * 设置光标在最后面
 * @param {*} obj 节点对象，document.getElementById('#id') 或者 $('.class')[0]
 */
const setCursorAtTheEnd = obj => {
    if (window.getSelection) {
        obj.focus();
        let range = window.getSelection();
        range.selectAllChildren(obj);
        range.collapseToEnd();
    } else if (document.selection) {
        let range = document.selection.createRange();
        //var range = document.body.createTextRange();
        range.moveToElementText(obj);
        range.collapse(false);
        range.select();
    }
};
const setCursortPosition = (element,pos) =>{
    /* var range = document.createRange();
    var sel = window.getSelection();
    console.log('setCursortPosition:', range);
    range.setStart(element.firstChild, position);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range); */
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    
    let currentPos = 0;
    let nodeFound = false;

    // 递归搜索节点以设置光标位置
    const searchNodes = (node) => {
        if (!nodeFound) {
            if (node.nodeType === Node.TEXT_NODE) {
                const nextPos = currentPos + node.length;
                if (pos <= nextPos) {
                    range.setStart(node, pos - currentPos);
                    range.collapse(true);
                    selection.addRange(range);
                    nodeFound = true;
                    return;
                }
                currentPos = nextPos;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                Array.from(node.childNodes).forEach(searchNodes);
            }
        }
    };

    searchNodes(element);

    // 如果位置超出最后可用位置，将光标置于最后
    if (!nodeFound) {
        const lastChild = element.lastChild;
        if (lastChild.nodeType === Node.TEXT_NODE) {
            range.setStart(lastChild, lastChild.length);
        } else {
            range.setStart(element, element.childNodes.length - 1);
        }
        range.collapse(true);
        selection.addRange(range);
    }
}
/**
 * 获取当前光标位置
 * @param {*} element 
 * @returns 
 */
const getCursorPosition = (element) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0 && document.activeElement === element) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.startContainer, range.startOffset);
        const position = preCaretRange.toString().length;
        return position;
    }
    return null;
}
/**
 * 获取当前光标位置
 * @param {*} element document.getElementById('#id') 或者 $('.class')[0]
 * @returns 
 */
const getCursortPosition = (element) => {
    var caretOffset = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {//谷歌、火狐
      sel = win.getSelection();
      if (sel.rangeCount > 0) {//选中的区域
        var range = win.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();//克隆一个选中区域
        preCaretRange.selectNodeContents(element);//设置选中区域的节点内容为当前节点
        preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {//IE
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
};
/**
 * 中英文字符统计，中文记标点符号，英文不记标点符号
 * @param {*} data 
 * @returns 
 */
const getWordCount = (data) => {
	var pattern = /[a-zA-Z0-9_\u0392-\u03c9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+|[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+/g;
	var m =data.match(pattern);
	var count = 0;
	if(m==null){return count;}
	for(var i=0;i<m.length;i++){
		if(m[i].charCodeAt(0)>=0x4E00){
			count +=m[i].length;
		}else{
			count += 1;
		}
	}
    return count;
}
/**
 * 详细字符统计
 * @param {*} content 需要统计字符的字符串
 * @returns {
 *  countTotal: 0,   //字符数
 *  countWord: 0,    //英文单词数
 *  countSymbol: 0,  //标点符号数
 *  countDigit: 0,   //数字符
 *  countChinese: 0, //中文字符
 *  countWordAll:0  //总单词数，中文字符 + 英文字符 + 标点符号数 + 数字符
 * }
 */
const getWordCountDetail = (content) => {
    if (content === '') {
        return {
            countTotal: 0,
            countWord: 0,
            countSymbol: 0,
            countDigit: 0,
            countChinese: 0,
            countWordAll: 0,
        };
    }
    let temp = '';
    let countTotal = content.length;
    temp = content.replace(/[^a-zA-Z\s]/g, '');
    temp = temp.split(/\s+/);
    temp = temp.filter(function(str) {
        return str !== '';
    });
    let countWord = temp.length;
    temp = content.match(/[\u4e00-\u9fa5]/g);
    let countChinese = temp === null ? 0 : temp.length;
    temp = content.match(/[^\x00-\xff]/g);
    let countChineseSymbol = temp === null ? 0 : (temp.length - countChinese);
    let pattern = /[^!"#$%&\\'()\*+,\-\.\/:;<=>?@\[\\\]\^_`{|}~']/g;
    temp = content.replace(pattern, '');
    let countEnglishSymbol = temp.length;
    let countSymbol = countChineseSymbol + countEnglishSymbol;
    temp = content.match(/[0-9]/g);
    let countDigit = temp === null ? 0 : temp.length;
    return {
        countTotal: countTotal,
        countWord: countWord,
        countSymbol: countSymbol,
        countDigit: countDigit,
        countChinese: countChinese,
        countWordAll: countChinese + countWord + countSymbol + countDigit
    };
};

/**
 * 验证用户名
 * @param {*} n 
 * @returns 
 */
const checkUserName = n =>{
    return (/^[A-Za-z0-9\.\-_@]{4,16}$/.test(n));
};
/**
* 验证手机号
*/ 
const checkPhone = n => {
    return (/^1([3456789])\d{9}$/.test(n))
};
/**
 * 验证密码6-20位大小写英文字母和数字
 * @param {*} n 
 */
const checkPassword = n =>{
    retrun (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{6,20})$/.test(n));
}
const checkEmail = (email) =>{
    return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
};
/**
 * 单词拆分成Array
 * @param {*} str 
 * @returns 
 */
const matchWords = str =>{
    let reg = /[a-zA-Z0-9_'\u0392-\u03c9\u0400-\u04FF\u0027]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af\u0400-\u04FF]+|[\u00E4\u00C4\u00E5\u00C5\u00F6\u00D6]+|[\u0531-\u0556\u0561-\u0586\u0559\u055A\u055B]+|\w+/g;
    let arr = str.match(reg);
    return {
        arr: arr,
        count: arr.length,
    };
}
/**
 * 浮点数 加法
 * @param {*}} arg1 
 * @param {*} arg2 
 * @returns 
 */
const floatAdd = (arg1,arg2)=>{
    var r1,r2,m;
    try{
        r1 = arg1.toString().split(".")[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2 = arg2.toString().split(".")[1].length;
    }catch(e){
        r2 = 0;
    }
    m=Math.pow(10, Math.max(r1,r2));
    return (arg1 * m + arg2 * m) / m
};
/**
* 浮点数 减法
*/
const floatSub = (arg1,arg2)=>{
    console.log("sssssS::::",arg1,arg2);
    var r1,r2,m,n;
    try{r1=arg1.split(".")[1].length}catch(e){r1=0}
    try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
    m=Math.pow(10,Math.max(r1,r2));
    n=(r1>=r2)?r1:r2;
    return ((arg1*m-arg2*m)/m).toFixed(n);
}
/**
* 浮点数 除法
*/
const floatDiv = (arg1,arg2)=>{
    var t1=0,t2=0,r1,r2;
    try{t1=arg1.toString().split(".")[1].length}catch(e){}
    try{t2=arg2.toString().split(".")[1].length}catch(e){}
    r1=Number(arg1.toString().replace(".",""))
    r2=Number(arg2.toString().replace(".",""))
    return (r1/r2)*Math.pow(10,t2-t1);
}
/**
* 浮点数 乘法
*/
const floatMul=(arg1,arg2)=>{
    var m=0,s1=arg1.toString(),s2=arg2.toString();
    try{m+=s1.split(".")[1].length}catch(e){}
    try{m+=s2.split(".")[1].length}catch(e){}
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
}
/**
 * 回到顶部
 */
const backTop = ()=>{
    const that = this
    // let timer = setInterval(() => {
      let ispeed = Math.floor(-that.scrollTop / 5)
      document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
    //   if (that.scrollTop === 0) {
    //     clearInterval(timer)
    //   }
    // }, 16)
};

const getEngineDist = (engine)=>{
    let rst = {};
    engine.map(domain=>{
        domain.engines.map(item=>{
            rst[item.key] = item;
        })
    })
    return rst;
}
/**
 * 句段添加样式，计算选择的片段和句段已拆分部分的关系
 * 返回类型定义
 * eq 相等
 * child 子
 * contain 包含
 * leftIntersect 左相交
 * rightIntersect 右相交
 * 
 * @param {*} list 原始句段拆分结果（起止位置）
 * @param {*} match 选中部分（起止位置）
 * @returns 索引:类型 字典
 */
function getIndexList(list,match){
    let indexes = {};
    list.map((item,index)=>{
        if(match[0] === item[0] && match[1] === item[1]){
            indexes[index] = 'eq';
        }else if(match[0] > item[0] && match[1] < item[1]){
            indexes[index] = 'child';
        }else if(match[0] <= item[0] && match[1] >= item[1]){
            indexes[index] = 'contain';
        }else if((match[0] <= item[0] && match[1] >= item[0] && match[1] < item[1])){
            indexes[index] = 'leftIntersect';
        }else if((match[0] > item[0] && match[0] <= item[1] && match[1] >= item[1])){
            indexes[index] = 'rightIntersect';
        }
    });
    return indexes;
}

function getTagNumber(){

}
 

const getTagText = (type,text,data,node,range)=>{
    let htmlStr = node.html();
    let textStr = node.text();
    console.log('getTagText:::111::',type,text,data,node,range);
    
    let rstArr = data.targetSegments;
    let rstList = [];
    if(rstArr && rstArr.length > 0){
        let sectionList = [];
        rstArr.map(item=>{
            sectionList.push([item.start,item.end])
        });
        //获取index list
        let indexes = getIndexList(sectionList,[range.anchorOffset,range.extentOffset]);
        for(let i=0;i<rstArr.length;i++){
            let arrItem = rstArr[i];
            let itype = indexes[i];
            if(itype){
                switch (itype){
                    case 'eq':
                    case 'contain':
                        rstList.push({
                            ...arrItem,
                            bold: type === 'bold' ? !arrItem.bold : arrItem.bold,
                            italic: type === 'italic' ? !arrItem.italic : arrItem.italic,
                            underline: type === 'underline' ? (arrItem.underline === 'NONE'?'SINGLE':'NONE'):arrItem.underline,
                        })
                    break;
                    case 'child':
                        // debugger
                        //left
                        let tls = textStr.substring(arrItem.start,range.anchorOffset);
                        if(tls){
                            rstList.push({
                                ...arrItem,
                                text: tls,
                                end:range.anchorOffset
                            });
                        }
                        //center
                        rstList.push({
                            ...arrItem,
                            text:text,
                            start:range.anchorOffset,
                            end:range.extentOffset,
                            bold: type === 'bold' ? !arrItem.bold : arrItem.bold,
                            italic: type === 'italic' ? !arrItem.italic : arrItem.italic,
                            underline: type === 'underline' ? (arrItem.underline === 'NONE'?'SINGLE':'NONE'):arrItem.underline,
                        });
                        //right
                        let trs = textStr.substring(range.extentOffset,arrItem.end);
                        if(trs){
                            rstList.push({
                                ...arrItem,
                                text: trs,
                                start: range.extentOffset,
                                end:arrItem.end
                            });
                        }
                    break;
                    case 'leftIntersect':
                        //left
                        rstList.push({
                            ...arrItem,
                            text: textStr.substring(arrItem.start,range.extentOffset),
                            bold: type === 'bold' ? !arrItem.bold : arrItem.bold,
                            italic: type === 'italic' ? !arrItem.italic : arrItem.italic,
                            underline: type === 'underline' ? (arrItem.underline === 'NONE'?'SINGLE':'NONE'):arrItem.underline,
                        });
                        //right
                        rstList.push({
                            ...arrItem,
                            text: textStr.substring(range.extentOffset,arrItem.end)
                        });
                        break;
                    case 'rightIntersect':
                        //left
                        rstList.push({
                            ...arrItem,
                            text: textStr.substring(arrItem.start,range.anchorOffset)
                        });
                        //right
                        rstList.push({
                            ...arrItem,
                            text: textStr.substring(range.anchorOffset,arrItem.end),
                            bold: type === 'bold' ? !arrItem.bold : arrItem.bold,
                            italic: type === 'italic' ? !arrItem.italic : arrItem.italic,
                            underline: type === 'underline' ? (arrItem.underline === 'NONE'?'SINGLE':'NONE'):arrItem.underline,
                        });
                        break;
                }
            }else{
                rstList.push(arrItem);
            }
        }
    }else{
        let rstText = textStr;
        let left = rstText.substring(0,range.anchorOffset);
        let center = rstText.substring(range.anchorOffset,range.extentOffset);
        let right = rstText.substring(range.extentOffset,rstText.length);
        if(left.length === 0){
            rstList.push({
                bold: type === 'bold',
                end: range.extentOffset,
                italic: type === 'italic',
                start: range.anchorOffset,
                text: center,
                underline: type === 'underline'?"SINGLE":"NONE",
                underlineColor: "#0000ff",
            })
        }else{
            rstList.push({
                bold: false,
                end: range.anchorOffset,
                italic: false,
                start: 0,
                text: left,
                underline: "NONE",
                underlineColor: "#0000ff",
            })
            rstList.push({
                bold: type === 'bold',
                end: range.extentOffset,
                italic: type === 'italic',
                start: range.anchorOffset,
                text: center,
                underline: type === 'underline'?"SINGLE":"NONE",
                underlineColor: "#0000ff",
            })
        }
        if(right.length>0){
            rstList.push({
                bold: false,
                end: rstText.length,
                italic: false,
                start: range.extentOffset,
                text: right,
                underline: "NONE",
                underlineColor: "#0000ff",
            })
        }
        
    }
    
    // console.log("xxxx::",JSON.stringify(rstList))
    return rstList;
}
/**
 * 生成批次号
 * @param {*} str 非必须
 * @returns 
 */
function generateBatchNo(str){
    let time = new Date().getTime();
    var rStr = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var random = '';
    for (var i = 8; i > 0; --i){
        random += rStr[Math.floor(Math.random() * rStr.length)];
    }
    return str ? md5(str + random + time) : md5(random + time);
}

function getRandomId(size){
    let rst = '';
    let mode = Array.from('0123456789abcdefghijklmnopqrstuvwxyz');
    for(let i = 0; i < size * 1; i++){
        let c = mode[Math.floor(Math.random()*mode.length)];
        if(rst.indexOf(c) > -1){
            i--;
            continue;
        }
        rst = rst + c;
    }
    return rst;
}
/**
 * 获取字符串中的某个字符所在的所有位置
 * @param {*} char 字符
 * @param {*} str 字符串
 * @param {*} cs 区分大小写
 * @returns {num:index}
 */
function getCharPostion(char, str, cs){
    if(!str || !char){
        return {};
    }
    let _char = char;
    let _str = str;
    if(!cs){
        _char=char.toLowerCase();
        _str=str.toLowerCase();
    }
    let index = _str.indexOf(_char);
    let num = 0;
    let dist = {};
    while(index !== -1){
        dist[num] = index;
        num++;
        index = _str.indexOf(_char, index + 1);
    }
    return dist;
}

/**
 * 获取媒体文件时长，返回毫秒
 * @param {*} file 文件
 * @returns 毫秒
 */
const getMediaFileTime = (file) => {
    return new Promise((resolve, reject) => {
        let url = URL.createObjectURL(file);
        var audio = new Audio(url);
        audio.onloadedmetadata = function() {
            let ms = Math.round(audio.duration * 1000);
            resolve(ms);
        };
        audio.onerror = function() {
            reject('error');
        };
    });
};
/**
 * 获取视频文件时长，返回毫秒
 * @param {*} file 文件
 * @returns 毫秒
 */
const getVideoFileTime = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ()=> {
            console.log('onload::', reader.result);
            let video = document.createElement('video');
            video.src = reader.result;
            console.log('canplay::', video);
            video.loadedmetadata = () => {
                let ms = Math.round(video.duration * 1000);
                resolve(ms);
            }
        }
        reader.onerror = () => {
            reject('error');
        }
    });
};

/**
 * 毫秒 转 hh:mm:ss
 */
const msToTime = (ms) => {
    let h = Math.floor(ms / 3600000);
    let _h = h > 9 ? h : '0' + h;
    let m = Math.floor((ms - h * 3600000) / 60000);
    let _m = m > 9 ? m : '0' + m;
    let s = Math.floor((ms - h * 3600000 - m * 60000) / 1000);
    let _s = s > 9 ? s : '0' + s;
    let result = '';
    if(h > 0){
        result = result + `${_h}时`;
    }
    if(m > 0){
        result = result + `${_m}分`;
    }
    if(s > 0){
        result = result + `${_s}秒`;
    }
    return result;
}


/**
 * 等待 ms 时间后执行
 * @param {*} ms 毫秒
 * @returns 
 */
const sleep = (ms) =>{
    return new Promise(resolve => setTimeout(resolve, ms));
}
/**
 * 使用正则表达式匹配并替换<g id="xx">...</g>格式的标签
 * @param {*} text 
 * @returns 
 */
const convertGNodeToTagNode = (text) => {
    return text.replace(/<g id="(\d+)"[\s\S]*?>([\s\S]*?)<\/g>/g, (match, id, content) => {
        return `<div id="${id}" tag="true" location="start" draggable="true"><span id="${id}" tag="true" unselectable="on"></span></div>${content}<div id="${id}" tag="true" location="end" draggable="true"><span id="${id}" tag="true" unselectable="on"></span></div>`;
    });
}
/**
 * 使用正则表达式提取 id 属性的值，匹配并替换具有 location="start" 和 location="end" 的标签
 * @param {*} text 
 * @returns 
 */
const convertTagNodeToGNode = (text) => {
    const startTagRegex = /<div\s+(?:[^>]*?\s+)?(id="([^"]+)"\s+[^>]*?location="start"|location="start"\s+[^>]*?id="([^"]+)")[^>]*>.*?<\/div>/g;
    let replacedText = text.replace(startTagRegex, (match, p1, id1, id2) => `<g id="${id1 || id2}">`);

    const endTagRegex = /<div\s+(?:[^>]*?\s+)?(id="([^"]+)"\s+[^>]*?location="end"|location="end"\s+[^>]*?id="([^"]+)")[^>]*>.*?<\/div>/g;
    replacedText = replacedText.replace(endTagRegex, '</g>');

    return replacedText;
}

const tagPositionChecking = (editerNode) => {
    // editerNode.find('span[zeroWidthSpace="true"]').remove();
    let html = editerNode.html();
    html = html.replaceAll('&ZeroWidthSpace;', '').replace(/<span zerowidthspace="true">(.*?)<\/span>/g, '$1').replace(/\u200B/g, '');
    editerNode.html(html);
    var tags = editerNode.find('div[tag="true"]');
    // 创建零宽空格span元素
    var zeroWidthSpaceSpan = '<span zeroWidthSpace="true">&ZeroWidthSpace;</span>';
    console.log('tagPositionChecking::', editerNode)
    tags.each(function(index) {
        var $this = $(this);
        $this.before(zeroWidthSpaceSpan);
        $this.after(zeroWidthSpaceSpan);
        /*
        var prevNode = this.previousSibling;
        var nextNode = this.nextSibling;

        var prevTextContent = "";
        while(prevNode) {
            if(prevNode.nodeType === Node.TEXT_NODE && prevNode.textContent.length > 0) {
                prevTextContent = prevNode.textContent;
                break;
            }
            prevNode = prevNode.previousSibling;
        }

        var nextTextContent = "";
        while(nextNode) {
            if(nextNode.nodeType === Node.TEXT_NODE && nextNode.textContent.length > 0) {
                nextTextContent = nextNode.textContent;
                break;
            }
            nextNode = nextNode.nextSibling;
        }

        if(prevTextContent.length === 0) {
            $this.before(zeroWidthSpaceSpan);
            // console.log('标签位于句子的开始:', this);
        }
        if(nextTextContent.length === 0) {
            $this.after(zeroWidthSpaceSpan);
            // console.log('标签位于句子的结束:', this);
        }

        if (index < tags.length - 1) {
            var nextTag = tags[index + 1];
            var hasTextBetween = false;

            var node = this.nextSibling;
            while (node && node !== nextTag) {
                if ((node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && !node.hasAttribute('tag'))) && node.textContent.length > 0) {
                    hasTextBetween = true;
                    break;
                }
                node = node.nextSibling;
            }

            if (!hasTextBetween) {
                $this.after(zeroWidthSpaceSpan);
                // console.log('两个标签之间没有文本:', this, nextTag);
            }
        } */
    });
};

export default {
    formatTime,
    strSplitAfterSome,
    checkUserName,
    checkPhone,
    checkPassword,
    checkEmail,
    matchWords,
    floatAdd,
    floatSub,
    floatDiv,
    floatMul,
    backTop,
    setCursorAtTheEnd,
    setCursortPosition,
    getCursorPosition,
    getCursortPosition,
    getWordCount,
    getWordCountDetail,
    getEngineDist,
    getTagText,
    generateBatchNo,
    getRandomId,
    getCharPostion,
    getMediaFileTime,
    getVideoFileTime,
    msToTime,
    sleep,
    convertGNodeToTagNode,
    convertTagNodeToGNode,
    tagPositionChecking,
}
