export default {
    common: {
        response_400: '错误请求',
        response_401: '登录状态已过期，请重新登录',
        response_403: '服务器拒绝响应，你可能没有权限',
        response_404: '请求错误，未找到该资源',
        response_405: '请求方法未允许',
        response_408: '请求超时',
        response_429: '操作过于频繁，请稍后再试',
        response_500: '服务器端出错',
        response_501: '网络未实现',
        response_502: '网络错误',
        response_503: '服务不可用',
        response_504: '网络超时',
        response_505: 'http版本不支持该请求',
        response_59574: '项目中包含未预翻译完成的文件不可下载',
        response_default: '连接错误，CODE：{code}',
        network_error: '处理失败，请检查网络',
        notice_title: '提醒',
        notice_message_1: '缓存失效，请刷新页面后重试。',
        notice_message_2: '任务已失效',
        notice_button_1: '刷新页面',
        notice_button_2: '返回任务列表',
        notice_button_3: '确定',
        exception_message_1: 'url没有传入！',
        country_code_list: [
            {name: '阿尔巴尼亚', code: '355'},
            // {name: '阿根廷', code: '54'},
            // {name: '阿拉伯联合酋长国', code: '971'},
            // {name: '阿鲁巴', code: '297'},
            // {name: '爱尔兰', code: '353'},
            {name: '爱沙尼亚', code: '372'},
            // {name: '安道尔', code: '376'},
            // {name: '安哥拉', code: '244'},
            // {name: '安圭拉', code: '1264'},
            // {name: '安提瓜和巴布达', code: '1268'},
            // {name: '奥地利', code: '43'},
            {name: '澳大利亚', code: '61'},
            // {name: '巴巴多斯', code: '1246'},
            // {name: '巴布亚新几内亚', code: '675'},
            // {name: '巴哈马', code: '1242'},
            // {name: '巴拉圭', code: '595'},
            // {name: '巴林', code: '973'},
            // {name: '巴拿马', code: '507'},
            // {name: '巴西', code: '55'},
            // {name: '百慕大群岛', code: '1441'},
            {name: '保加利亚', code: '359'},
            // {name: '比利时', code: '32'},
            // {name: '冰岛', code: '354'},
            // {name: '波多黎各', code: '1787'},
            {name: '波兰', code: '48'},
            // {name: '波斯尼亚和黑塞哥维那', code: '387'},
            // {name: '伯利兹', code: '501'},
            // {name: '博茨瓦纳', code: '267'},
            // {name: '布基纳法索', code: '226'},
            // {name: '布隆迪', code: '257'},
            // {name: '朝鲜', code: '850'},
            // {name: '赤道几内亚', code: '240'},
            {name: '丹麦', code: '45'},
            // {name: '东帝汶', code: '670'},
            // {name: '多哥', code: '228'},
            // {name: '多米尼加共和国', code: '1809'},
            // {name: '多米尼克', code: '1767'},
            // {name: '厄立特里亚', code: '291'},
            {name: '法国', code: '33'},
            // {name: '法罗群岛', code: '298'},
            // {name: '斐济', code: '679'},
            {name: '芬兰', code: '358'},
            // {name: '佛得角', code: '238'},
            // {name: '冈比亚', code: '220'},
            // {name: '刚果共和国', code: '242'},
            // {name: '刚果民主共和国（扎伊尔）', code: '243'},
            // {name: '哥伦比亚', code: '57'},
            // {name: '哥斯达黎加', code: '506'},
            // {name: '格林纳达', code: '1473'},
            // {name: '格陵兰岛', code: '299'},
            // {name: '古巴', code: '53'},
            // {name: '关岛', code: '1671'},
            // {name: '圭亚那', code: '592'},
            // {name: '哈萨克斯坦', code: '997'},
            // {name: '海地', code: '509'},
            {name: '韩国', code: '82'},
            {name: '荷兰', code: '31'},
            // {name: '黑山', code: '382'},
            // {name: '洪都拉斯', code: '504'},
            // {name: '基里巴斯', code: '686'},
            // {name: '吉布提', code: '253'},
            // {name: '几内亚', code: '224'},
            // {name: '几内亚比绍共和国', code: '245'},
            {name: '加拿大', code: '1'},
            // {name: '加蓬', code: '241'},
            {name: '捷克', code: '420'},
            // {name: '津巴布韦', code: '263'},
            // {name: '喀麦隆', code: '237'},
            // {name: '卡塔尔', code: '974'},
            // {name: '开曼群岛', code: '1345'},
            // {name: '科摩罗', code: '269'},
            // {name: '科特迪瓦', code: '225'},
            {name: '克罗地亚', code: '385'},
            // {name: '肯尼亚', code: '254'},
            // {name: '拉脱维亚', code: '371'},
            // {name: '莱索托', code: '266'},
            {name: '老挝', code: '856'},
            {name: '立陶宛', code: '370'},
            // {name: '利比里亚', code: '231'},
            // {name: '列支敦士登', code: '423'},
            // {name: '卢森堡', code: '352'},
            // {name: '卢旺达', code: '250'},
            {name: '罗马尼亚', code: '40'},
            // {name: '马达加斯加', code: '261'},
            // {name: '马尔代夫', code: '960'},
            // {name: '马耳他', code: '356'},
            {name: '马来西亚', code: '60'},
            // {name: '马里亚纳群岛', code: '1670'},
            {name: '马其顿', code: '389'},
            // {name: '马约特', code: '262'},
            // {name: '毛里求斯', code: '230'},
            {name: '美国', code: '1'},
            // {name: '美属萨摩亚', code: '1684'},
            // {name: '美属维尔京群岛', code: '1340'},
            // {name: '蒙特塞拉特岛', code: '1664'},
            // {name: '秘鲁', code: '51'},
            // {name: '密克罗尼西亚', code: '691'},
            // {name: '摩尔多瓦', code: '373'},
            // {name: '摩洛哥', code: '212'},
            // {name: '摩纳哥', code: '377'},
            // {name: '莫桑比克', code: '258'},
            // {name: '墨西哥', code: '52'},
            // {name: '纳米比亚', code: '264'},
            // {name: '南非', code: '27'},
            // {name: '南苏丹', code: '211'},
            // {name: '尼加拉瓜', code: '505'},
            // {name: '尼日尔', code: '227'},
            // {name: '挪威', code: '47'},
            // {name: '诺福克岛', code: '6723'},
            // {name: '帕劳', code: '680'},
            {name: '葡萄牙', code: '351'},
            {name: '日本', code: '81'},
            {name: '瑞典', code: '46'},
            // {name: '瑞士', code: '41'},
            // {name: '萨尔瓦多', code: '503'},
            // {name: '萨摩亚', code: '685'},
            // {name: '塞尔维亚', code: '381'},
            {name: '塞拉利昂', code: '232'},
            // {name: '塞浦路斯', code: '357'},
            // {name: '塞舌尔', code: '248'},
            // {name: '沙特阿拉伯', code: '966'},
            // {name: '圣彼埃尔和密克隆岛', code: '508'},
            // {name: '圣多美和普林西比', code: '239'},
            // {name: '圣基茨和尼维斯', code: '1869'},
            // {name: '圣卢西亚', code: '1758'},
            // {name: '圣马丁岛（荷兰部分）', code: '1721'},
            // {name: '圣马力诺', code: '378'},
            // {name: '圣文森特和格林纳丁斯', code: '1784'},
            {name: '斯洛伐克', code: '421'},
            // {name: '斯洛文尼亚', code: '386'},
            // {name: '斯威士兰', code: '268'},
            // {name: '苏里南', code: '597'},
            // {name: '所罗门群岛', code: '677'},
            {name: '泰国', code: '66'},
            // {name: '坦桑尼亚', code: '255'},
            // {name: '汤加', code: '676'},
            // {name: '特克斯和凯科斯群岛', code: '1649'},
            // {name: '特立尼达和多巴哥', code: '1868'},
            {name: '土耳其', code: '90'},
            // {name: '瓦努阿图', code: '678'},
            // {name: '危地马拉', code: '502'},
            // {name: '委内瑞拉', code: '58'},
            // {name: '文莱', code: '673'},
            // {name: '乌拉圭', code: '598'},
            {name: '西班牙', code: '34'},
            {name: '希腊', code: '30'},
            {name: '新加坡', code: '65'},
            // {name: '新喀里多尼亚', code: '687'},
            {name: '匈牙利', code: '36'},
            // {name: '牙买加', code: '1876'},
            {name: '意大利', code: '39'},
            {name: '印度', code: '91'},
            {name: '英国', code: '44'},
            // {name: '英属维尔京群岛', code: '1284'},
            {name: '越南', code: '84'},
            // {name: '赞比亚', code: '260'},
            // {name: '直布罗陀', code: '350'},
            // {name: '智利', code: '56'},
            // {name: '中非共和国', code: '236'},
            {name: '中国澳门', code: '853'},
            {name: '中国大陆', code: '86'},
            {name: '中国台湾', code: '886'},
            {name: '中国香港', code: '852'},
        ]
    },
    component: {
        LanguageSelector_autoLang_result: '检测到{name}',
        LanguageSelector_autoLang_label: '自动检测',
        LanguageSelector_button_exchang: '点击切换',
        LanguageSelector_source_language_name: '中文',
        LanguageSelector_source_language_short_name: '中',
        LanguageSelector_target_language_name: '英语',
        LanguageSelector_target_language_short_name: '英',
        LanguageSelector_search_input_placeholder: '查找语言',

        LanguageSelect_button_exchang: '点击切换',

        PageHeader_vip_name: '套餐',
        PageHeader_help_item_1: '帮助文档',
        PageHeader_help_item_2: '使用反馈',
        PageHeader_help_item_3: '用户社区',
        PageHeader_help_item_4: '申请开票',
        PageHeader_version_title: '版本升级',
        PageHeader_version_label_new: '新',

        PageHeaderTabs_tab_memory: '记忆库',
        PageHeaderTabs_tab_term: '术语库',
        PageHeaderTabs_tab_polish: 'AI润色',
        PageHeaderTabs_tab_translationScore: 'AI翻译评分',
        PageHeaderTabs_tab_align: '语料对齐',
        PageHeaderTabs_tab_termExtraction: '术语提取',
        PageHeaderTabs_tab_qa: 'QA检查',
        PageHeaderTabs_tab_detector: '机翻检测',
        PageHeaderTabs_tab_videoTranslate: 'AI译配',
        PageHeaderTabs_tab_gptTranslate: '文档智能体',
        PageHeaderTabs_tab_ragCreate: '定制RAG模型',
        PageHeaderTabs_tab_engineCreate: '深度训练',

        DownloadNotices_drag_tips: '按下鼠标移动位置',
        DownloadNotices_status_name_1: '导出中，请耐心等待',
        DownloadNotices_status_name_2: '导出成功',
        DownloadNotices_status_name_3: '导出失败',
        DownloadNotices_button_download: '点击下载',
        DownloadNotices_download_link_fail_message: '获取下载地址失败，请重试',
        DownloadNotices_download_delete_remote_file_success: '删除远程下载资源成功',

        DragDialog_title: '提示',

        MessageCenter_title: '消息中心',
        MessageCenter_button_read_all: '全部已读',
        MessageCenter_tab_label_all: '全部',
        MessageCenter_tab_label_unread: '未读',
        MessageCenter_tab_label_read: '已读',
        MessageCenter_message_createTime_format: 'MM月DD日 HH:mm',
        MessageCenter_button_join: '加入',
        MessageCenter_button_go_review: '去审核',
        MessageCenter_no_message_tips: '暂无消息',

        UserAvatarMenu_expireTime_format: 'YYYY-MM-DD',
        UserAvatarMenu_expireTime_label: '到期',
        UserAvatarMenu_expireTime_label_expired: '已过期',
        UserAvatarMenu_upgrade_to_pro: '升级PRO版',
        UserAvatarMenu_info_label_1: '成员数：',
        UserAvatarMenu_info_label_2: '总字符：',
        UserAvatarMenu_info_label_3: 'Token数：',
        UserAvatarMenu_button_switch_org: '切换组织',
        UserAvatarMenu_button_user_center: '个人中心',
        UserAvatarMenu_button_org_center: '企业中心',
        UserAvatarMenu_button_logout: '退出登录',
        UserAvatarMenu_message_switching_org: '正在切换组织...',

        LeftAside_slogan: '新一代AI翻译平台',
        LeftAside_menu_item_help: '帮助',
        LeftAside_menu_item_help_child_1: '帮助文档',
        LeftAside_menu_item_help_child_2: '使用反馈',
        LeftAside_menu_item_help_child_3: '用户社区',
        LeftAside_menu_item_help_child_4: '申请开票',
        LeftAside_menu_item_engine: '引擎',
        LeftAside_menu_item_project: '项目',
        LeftAside_menu_item_task: '任务',
        LeftAside_menu_item_asset: '资产',
        LeftAside_menu_item_tools: '工具',
        LeftAside_menu_item_agent: '智能体',
        LeftAside_menu_item_team: '团队',

        AlertDialog_title: '提示',
        AlertDialog_button_confirm: '确定',
        AlertDialog_button_cancel: '取消',

        BindWechat_title: '绑定微信',
        BindWechat_scan_qrcode_title: '微信扫码',
        BindWechat_button_refresh: '点击刷新',
        BindWechat_qrcode_overdue_tips: '二维码已过期',
        BindWechat_tips_1: '扫码并关注【LanguageX】公众号，',
        BindWechat_tips_2: '我们的信息不会太多，但对你很重要~',
        BindWechat_bind_success_message: '绑定成功', 

        FullLoading_title: '查询中...',

        JargonDialog_title: '术语定制（项目术语库）',
        JargonDialog_tips: '此术语库为项目自动创建，你新建项目上传或挂载的术语已生效，并保存在翻译记忆下的术语库中。',
        JargonDialog_tooltip_content: '支持xlsx/xls/tbx格式',
        JargonDialog_button_upload_title: '上传术语库',
        JargonDialog_button_download_template: '下载模板',
        JargonDialog_input_search_placeholder: '搜索术语',
        JargonDialog_table_column_index_label: '序号',
        JargonDialog_table_column_source_label: '原文',
        JargonDialog_table_column_target_label: '译文',
        JargonDialog_table_column_remark_label: '备注',
        JargonDialog_table_column_action_label: '操作',
        JargonDialog_input_source_placeholder: '输入术语原文',
        JargonDialog_input_target_placeholder: '输入术语译文',
        JargonDialog_input_remark_placeholder: '输入备注',
        JargonDialog_button_save: '保存',
        JargonDialog_button_cancel: '取消',
        JargonDialog_button_edit: '编辑',
        JargonDialog_button_delete: '删除',
        JargonDialog_button_reTranslate: '重译',
        JargonDialog_button_delete_confirm_title: '确定删除吗？',
        JargonDialog_downloading_template_message: '下载中...',
        JargonDialog_download_fail_message: '获取下载地址失败',
        JargonDialog_term_upload_verification_message: '请确认已上传文件并上传成功',
        JargonDialog_term_upload_success_message: '上传成功',
        JargonDialog_term_source_verification_message: '术语原文不能为空',
        JargonDialog_term_target_verification_message: '术语译文不能为空',
        JargonDialog_save_success_message: '保存成功',
        JargonDialog_delete_success_message: '已删除',

        NextCollapse_title: '标题',

        NextDrawer_button_close: '关闭',
        NextDrawer_button_confirm: '确认',

        PageTitle_input_title_placeholder: '输入标题，回车保存',
        PageTitle_edit_title_tooltip_content: '点击编辑，回车保存',
        PageTitle_save_title_verification_message: '名称不能为空',

        PayErpDialog_title: '购买企业版',
        PayErpDialog_success_message_1: '企业版订阅成功',
        PayErpDialog_success_message_2: '感谢您的信任，欢迎加入用户交流群~',
        PayErpDialog_success_message_3: '扫码加入官方交流群',
        PayErpDialog_button_apply_invoice: '申请开票',
        PayErpDialog_form_startTime_label: '服务开始时间',
        PayErpDialog_form_startTime_placeholder: '选择服务开始时间',
        PayErpDialog_form_endTime_label: '服务结束时间',
        PayErpDialog_form_endTime_placeholder: '选择服务结束时间',
        PayErpDialog_form_orgName_label: '企业全称',
        PayErpDialog_form_orgName_placeholder: '企业全称，必填',
        PayErpDialog_form_button_cofirm: '确认并生成协议',
        PayErpDialog_form_orgName_tips_1: '如果企业全称显示为LXO开头的内容，',
        PayErpDialog_form_orgName_tips_2: '请修改',
        PayErpDialog_form_orderNo_label: '订单编号',
        PayErpDialog_form_amount_label: '付款金额',
        PayErpDialog_form_scan_label: '扫码支付',
        PayErpDialog_form_scan_platform_wechat: '微信',
        PayErpDialog_currency_symbol: '￥',
        PayErpDialog_form_org_benefits_tips: '了解企业版本更多权益',
        PayErpDialog_form_cofirm_tips_1: '点击“确认并生成协议”按钮',
        PayErpDialog_form_cofirm_tips_2: '在新页面确认合同后返回此页面付款',
        PayErpDialog_bottom_tips_1: '订阅LanguageX服务，即表示同意我们的',
        PayErpDialog_bottom_tips_2: '《服务协议》',
        PayErpDialog_bottom_tips_3: '和',
        PayErpDialog_bottom_tips_4: '《隐私政策》',
        PayErpDialog_pay_fail_message: '支付失败!',
        PayErpDialog_orgName_verification_message: '请填写企业全称!',
        PayErpDialog_orgName_LOX_verification_message: '请联系当前企业管理员修改企业名称后再试',

        PayProDialog_title: '购买PRO版',
        PayProDialog_success_message_1: '会员订阅成功',
        PayProDialog_success_message_2: '感谢您的信任，欢迎加入用户交流群~',
        PayProDialog_success_message_3: '扫码加入官方交流群',
        PayProDialog_button_apply_invoice: '申请开票',
        PayProDialog_currency_symbol: '￥',
        PayProDialog_label_time_limit: '限时',
        PayProDialog_form_orderNo_label: '订单编号',
        PayProDialog_form_amount_label: '付款金额',
        PayProDialog_form_scan_label: '扫码支付',
        PayProDialog_form_scan_platform_wechat: '微信',
        PayProDialog_form_org_benefits_tips: '了解PRO版本更多权益',
        PayProDialog_bottom_tips_1: '订阅LanguageX服务，即表示同意我们的',
        PayProDialog_bottom_tips_2: '《服务协议》',
        PayProDialog_bottom_tips_3: '和',
        PayProDialog_bottom_tips_4: '《隐私政策》',
        PayProDialog_pay_fail_message: '支付失败!',

        QaSetting_title: 'QA设置',
        QaSetting_type_title_1: '规则QA设置',
        QaSetting_type_title_2: '智能QA设置',
        QaSetting_support_tooltip_content: '智能QA只对Pro版开放，Free用户QA只提供规则QA',
        QaSetting_fluency_tips: '英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）',
        QaSetting_button_cancel: '取消',
        QaSetting_button_submit: '确定',
        QaSetting_success_message: 'QA配置已更新',

        UpgradePro_message_1: '请先完成账户升级',
        UpgradePro_pro_limited_label: '已到期',
        UpgradePro_message_2: '当前正在使用的版本，暂无法使用该功能。升级账户解锁更多高级功能',
        UpgradePro_button_upgrade: '升级到PRO',

        UploadFiles_file_upload_status_fail: '失败',
        UploadFiles_file_upload_status_success: '成功',
        UploadFiles_file_accept_message: '支持{accept}格式的文件',
        UploadFiles_file_limit_message: '最多只能上传{limit}个文件',
        UploadFiles_file_size_message: '{name}大于{size}MB',
        UploadFiles_file_exist_message: '{name}已存在',
        UploadFiles_login_expired_message: '登录状态已过期，请重新登录',
        UploadFiles_file_upload_fail_message: '{name}上传失败，请重试',

        UploadOss_title: '上传文件',
        UploadOss_file_accept_message: '支持{accept}格式的文件',
        UploadOss_file_limit_message: '最多只能上传{limit}个文件',
        UploadOss_file_empty_message: '不支持上传空文件',
        UploadOss_file_size_message: '{name}大于{size}MB',
        UploadOss_file_exist_message: '{name}已存在',
        UploadOss_file_upload_fail_message: '文件上传失败，请重试',

        UserSelect_select_placeholder: '请选择',

        UserSelector_search_input_placeholder: '输入关键字搜索',
        UserSelector_empty_description: '暂无内容',

        UserSelectorDialog_title: '分配译员',
        UserSelectorDialog_form_name_label: '译员名称',
        UserSelectorDialog_form_name_placeholder: '请选择',
        UserSelectorDialog_button_cancel: '取消',
        UserSelectorDialog_button_submit: '确定',













    },
    engine: {
        config_page_title: '翻译引擎',
        config_page_button_term_setting: '术语定制',
        config_page_source_input_placeholder: '粘贴一段文字，快速对比不同引擎翻译结果，每次最多2000字',
        config_page_button_change: '换一换',
        config_page_button_try: '试一试样例',
        config_page_button_upload: '上传文件',
        config_page_tooltip_engine_detail: '查看引擎详情',
        config_page_tooltip_engine_create: '新建引擎',
        config_page_message_1: '除了通用引擎，你还可以',
        config_page_message_2: '训练自己的个性引擎',
        config_page_message_3: '当前语言方向没有个性引擎，试试自己新建一个',
        config_page_message_4: '你的PRO版账号已过期，创建的个性引擎已暂停服务，续费后恢复服务，',
        config_page_message_5: '去续费',
        config_page_message_6: '翻译中，请稍等',
        config_page_message_7: '个性引擎因会员到期已下架，续订后会恢复服务~',
        config_page_message_8: 'Pro版专属引擎，升级为Pro版可用。',
        config_page_message_9: '去升级',
        config_page_message_10: '已复制到剪切板',
        config_page_message_11: '复制到剪切板失败，请重试',
        config_page_message_12: '请选择源语言',
        config_page_message_13: '术语定制已开启',
        config_page_message_14: '术语定制已关闭',
        config_page_message_15: '当前语言方向下无此术语',
        config_page_message_16: '原文：',
        config_page_message_17: '最多润色{count}次',
        
        config_page_button_copy: '复制',
        config_page_button_edit: '编辑',
        config_page_button_show_difference: '显示差异',
        config_page_button_clear_history: '清空历史',
        config_page_button_polish: '润色',
        config_page_button_polish_again: '再次润色',

        config_page_term_title: '术语定制',
        config_page_term_descoption: '添加术语试一试，每个语言方向最多20个。如需批量添加术语和翻译文件，点击上传文件去新建项目。',
        config_page_term_table_label_1: '序号',
        config_page_term_table_label_2: '原文（{name}）',
        config_page_term_table_label_3: '译文（{name}）',
        config_page_term_table_label_4: '操作',
        config_page_term_table_input_1: '输入术语原文',
        config_page_term_table_input_2: '输入术语译文',
        config_page_term_table_button_1: '保存（Enter）',
        config_page_term_table_button_2: '删除',
        config_page_term_table_button_3: '编辑',
        config_page_term_verification_message_1: '术语原文不能为空',
        config_page_term_verification_message_2: '术语译文不能为空',
        config_page_term_message_1: '保存术语成功',
        config_page_term_message_2: '术语已删除',
        
        ragCreate_page_title: '定制RAG模型',
        ragCreate_page_alert_1_part1: '{type}最多能创建{size}个个性引擎，你的账户创建数量已达到上限，如需新建个性引擎，',
        ragCreate_page_alert_1_part2: '请联系我们',
        ragCreate_page_alert_2: 'RAG（检索增强式生成）可以让大语言模型结合记忆库/术语库和特殊要求，更专业地翻译垂直领域内容',
        ragCreate_page_form_model_label: '模型名称',
        ragCreate_page_form_model_placeholder: '给自己翻译引擎起个名字，不超过10个字符',
        ragCreate_page_form_language_label: '语言方向',
        ragCreate_page_form_language_source_placeholder: '源语言',
        ragCreate_page_form_language_target_placeholder: '目标语言',
        ragCreate_page_form_term_label: '指定术语翻译',
        ragCreate_page_form_term_tips: '在未上传术语库的情况下，指定少数项目特定的核心术语（如果术语量大请上传术语库）',
        ragCreate_page_form_term_source_placeholder: '输入术语原文',
        ragCreate_page_form_term_target_placeholder: '输入术语译文',
        ragCreate_page_form_demand_label: '其他要求',
        ragCreate_page_form_demand_tooltip_content: '如:请使用简短精炼的语言描述特定翻译要求，以下是一些例子：<br>1. 百分号直接使用%，无需翻译成percent。<br>2. 不要翻译文本中出现的公司名称，保留公司名原文。<br>3. 如果文中涉及序号，将“一、”译为“I.”。',
        ragCreate_page_form_demand_placeholder: '如：请使用简短精炼的语言描述特定翻译要求，以下是一些例子：\n1. 百分号直接使用%，无需翻译成percent。\n2. 不要翻译文本中出现的公司名称，保留公司名原文。\n3. 如果文中涉及序号，将“一、”译为“I.”。',
        ragCreate_page_form_description_label: '模型简介',
        ragCreate_page_form_description_placeholder: '请输入简介，100字以内例：国际工程领域，语料主要来自工程技术文件',
        ragCreate_page_form_memoryFile_label: '上传记忆库',
        ragCreate_page_form_memoryFile_tips: '语言方向需和引擎的语言方向一致',
        ragCreate_page_form_memoryFile_message: '上传xlsx/xls/tmx格式记忆库<br>每批最多50个文件，单个最大{size}MB',
        ragCreate_page_form_termFile_label: '上传术语',
        ragCreate_page_form_termFile_tips: '语言方向需和引擎的语言方向一致',
        ragCreate_page_form_termFile_message: '上传xlsx/xls/tbx格式术语库<br>每批最多50个文件，单个最大{size}MB',
        ragCreate_page_button_submit: '生成模型',
        ragCreate_page_button_submit_message: '预计1分钟以内',
        ragCreate_page_success_dialog_title: '生成成功',
        ragCreate_page_success_dialog_content_1: '{name}模型已生成成功',
        ragCreate_page_success_dialog_content_2: '可以到【引擎】>【个性引擎】下使用',
        ragCreate_page_success_dialog_content_3: '使用前先选择正确的语言方向',
        ragCreate_page_success_dialog_button: '去使用',
        ragCreate_page_language_zh_name: '中文',
        ragCreate_page_language_zh_shortName: '中',
        ragCreate_page_language_en_name: '英语',
        ragCreate_page_language_en_shortName: '英',
        ragCreate_page_language_ko_name: '韩语',
        ragCreate_page_language_ko_shortName: '韩',
        ragCreate_page_form_verification_message_1: '请输入模型名称',
        ragCreate_page_form_verification_message_2: '请上传记忆库',
        ragCreate_page_form_verification_message_3: '最多添加20个术语',

        create_page_title: '深度训练',
    },
    project: {
    },
    asset: {
    },
    tools: {
    },
    system: {
        slogan: '新一代AI翻译平台',

        basic_left_tagline_1: '全球35款翻译引擎，极致个性化AI翻译',
        basic_left_tagline_2: '基于大模型的AI工具，功能强大/操作极简',
        basic_left_tagline_3: '专业翻译项目管理，完善的多人协作流程',

        login_page_service_terms: '服务协议',
        login_page_privacy_policy: '隐私政策',

        login_page_title: '用户登录',
        login_page_form_title: 'LOGIN',
        login_page_tab_phone: '手机号',
        login_page_tab_account: '邮箱/用户名',
        login_page_form_phone_placeholder: '手机号',
        login_page_form_account_placeholder: '邮箱/用户名',
        login_page_form_password_placeholder: '密码',
        login_page_form_button: '登录',
        login_page_link_forgot: '忘记密码',
        login_page_link_signup: '注册',
        login_page_link_password: '密码登录',
        login_page_link_login: '登录',
        login_page_wechat: '微信登录',
        login_page_wechat_title: '微信登录',
        login_page_wechat_subtitle: '打开微信扫一扫登录',
        login_page_wechat_reload: '点击刷新',
        login_page_wechat_qrcode_overdue: '二维码已过期',
        login_page_bindphone_title: '绑定手机号',
        login_page_bindphone_form_phone_placeholder: '请输入手机号',
        login_page_bindphone_form_captcha_placeholder: '输入验证码',
        login_page_bindphone_form_send_captcha: '发送验证码',
        login_page_bindphone_form_send_captcha_seconds: 's后可发送',
        login_page_form_verification_message_1: '账号和密码不能为空',
        login_page_form_verification_message_2: '请填写手机号',
        login_page_form_verification_message_3: '手机号格式不正确',
        login_page_form_verification_message_4: '手机号和密码不能为空',
        login_page_form_sent_captcha_message: '验证码已发送，请注意查收',
        login_page_bindphone_form_verification_message_1: '请输入手机号',
        login_page_bindphone_form_verification_message_2: '手机号格式不正确',
        login_page_bindphone_form_verification_message_3: '请输入验证码',

        forget_page_title: '忘记密码',
        forget_page_form_title: '忘记密码',
        forget_page_form_phone_placeholder: '输入手机号',
        forget_page_form_captcha_placeholder: '输入验证码',
        forget_page_form_password_placeholder: '输入新密码',
        forget_page_form_button: '确定',
        forget_page_form_verification_message_1: '请输入手机号',
        forget_page_form_verification_message_2: '手机号格式不正确',
        forget_page_form_verification_message_3: '请输入验证码',
        forget_page_form_verification_message_4: '请输入密码',
        forget_page_form_verification_message_5: '密码必须包含小写字母和数字，长度6-20位',
        forget_page_form_submit_success: '密码修改成功, 1s后自动跳转到登录页面。',
        forget_page_form_sent_captcha_message: '验证码已发送，请注意查收',

        signup_page_title: '用户注册',
        signup_page_form_title: '手机号注册',
        signup_page_form_phone_placeholder: '输入手机号',
        signup_page_form_captcha_placeholder: '输入验证码',
        signup_page_form_password_placeholder: '输入密码',
        signup_page_form_invite_code_placeholder: '输入邀请码（选填）',
        signup_page_form_send_captcha: '发送验证码',
        signup_page_form_send_captcha_seconds: 's后可发送',
        signup_page_form_clause_prefix: '注册即代表同意',
        signup_page_form_button: '注册',
        signup_page_form_verification_message_1: '请输入手机号',
        signup_page_form_verification_message_2: '手机号格式不正确',
        signup_page_form_verification_message_3: '请输入验证码',
        signup_page_form_verification_message_4: '请输入密码',
        signup_page_form_verification_message_5: '密码必须包含小写字母和数字，长度6-20位',
        signup_page_form_submit_success: '注册成功,2s后自动进入系统',
        signup_page_form_sent_captcha_message: '验证码已发送，请注意查收',

        check_page_title: '切换组织',
        check_page_switch_header: '切换组织',
        check_page_switch_message_1: '当前正在登录',
        check_page_switch_message_2: '是否要切换至',
        check_page_switch_message_3: '组织？',
        check_page_switch_button_1: '取消',
        check_page_switch_button_2: '确定',
    },
    account: {
    },
    
}


