<template>
    <div class="page-header-component">
        <div class="left">
            <div class="title" v-if="showTitle">{{ pageTitle }}</div>
            <template v-else>
                <page-header-tabs></page-header-tabs>
            </template>
        </div>
        <div class="right">
            <template v-for="(item, index) in noticeList">
                <div class="expand-item" :key="index" @click="noticeClickEvent(item)">
                    <div class="dot-content"><div class="dot"></div></div>
                    <div class="text">{{item.title}}</div>
                </div>
            </template>
            <router-link to="/account/vip" class="expand-item" style="background-color: #ffebdb;">
                <div class="fonts-20 margin-r-10">
                    <svg-icon name="ic-crown"></svg-icon>
                </div>
                <div class="text" style="color: #000;">{{ $t('component.PageHeader_vip_name') }}</div>
            </router-link>
        </div>
        <div v-if="userInfo">
            <message-center></message-center>
            <div class="help-wrapper margin-l-10">
                <el-dropdown @command="handleCommand">
                    <div class="help">
                    <svg-icon name="ib-question"></svg-icon>
                    </div>
                    <el-dropdown-menu>
                        <el-dropdown-item command="tohelp">
                            <svg-icon name="ib-help-o" className="fonts-14"></svg-icon>
                            {{ $t('component.PageHeader_help_item_1') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="feedback">
                            <svg-icon name="ib-feedback" className="fonts-14"></svg-icon>
                            {{ $t('component.PageHeader_help_item_2') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="community">
                            <svg-icon name="ib-community" className="fonts-14"></svg-icon>
                            {{ $t('component.PageHeader_help_item_3') }}
                        </el-dropdown-item>
                        <el-dropdown-item command="invoicing">
                            <svg-icon name="ib-invoicing" className="fonts-14"></svg-icon>
                            {{ $t('component.PageHeader_help_item_4') }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- <user-avatar-menu class="margin-l-10"></user-avatar-menu> -->
        </div>
        
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import PageHeaderTabs from '@/components/PageHeaderTabs';
import VipIcon from "@/components/icon/VipIcon";
import MessageCenter from '@/components/MessageCenter';
import UserAvatarMenu from '@/components/UserAvatarMenu';
import { userApi } from "@/utils/api";

export default {
    name: 'PageHeader',
    components: { VipIcon, PageHeaderTabs, MessageCenter, UserAvatarMenu, },
    data () {
        return {
            pageTitle: '',
            showTitle: true,
            updateNotice: null,
        }
    },
    computed: {
        ...mapState({
			userInfo: state => state.user.userData,
            noticeList: state => state.common.noticeList,
		}),
        ...mapGetters({
            isLogin:'user/loginStatus',
        }),
    },

    methods: {
        handleCommand(command){
            let self = this;
            if(command === 'logout'){
                let url= userApi.logout;
                this.$ajax.delete(url,{}).then(res=>{
                    if(res.status === 200){
                        localStorage.removeItem("userInfo");
                        setTimeout(()=>{
                            // location.reload(true);
                            self.$router.replace('/login');
                        },500);
                    }
                });
            }else if(command === 'changepass'){
                this.changeDialogShow = true;
            }else if(command === 'usercenter'){
                this.$router.push('/account/vip');
            }else if(command === 'tohelp'){
                window.open('https://m15er90ta0.feishu.cn/docs/doccnpnEA2swEXwJ8yyeGs2sZFb','_blank');
            }else if(command === 'feedback'){
                window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnHaLlI7xeeD1FNZ6nyLHAW7','_blank');
            }else if(command === 'community'){
                window.open('https://support.qq.com/products/413842/','_blank');
            }else if(command === 'invoicing'){
                window.open('https://m15er90ta0.feishu.cn/share/base/form/shrcnt4Z1ziC7IVeP7AuTq8ffDc', '_blank');
            }
        },
        noticeClickEvent(params){
            switch(params.action){
                case 'TOAST':
                    if(this.updateNotice !== null){
                        this.updateNotice.close();
                        this.updateNotice = null;
                    }else{
                        this.updateNotice = this.$notify({
                            duration: 0,
                            offset: 40,
                            title: this.$t('component.PageHeader_version_title'),
                            customClass: 'version-content',
                            dangerouslyUseHTMLString: true,
                            onClose: ()=>{
                                this.updateNotice = null;
                            },
                            message: `<div class="time">${params.createTime}</div>` +
                                `<div class="version-title"><span class="new">${this.$t('component.PageHeader_version_label_new')}</span><span class="version">${params.navigationVersion}</span></div>` +
                                `<div class="description">${params.text}</div>`
                        });
                    }
                    break;
                case 'LINK':
                    let link = params.link;
                    if(link.startsWith('http://')||link.startsWith('https://')){
                        window.open(link, '_blank');
                    }else{
                        let newpage = this.$router.resolve({path: link});
                        window.open(newpage.href, '_blank');
                        // this.$router.push(link);
                    }
                    break;
            }
        },
        initTitle(){
            let route = this.$route;
            let routeNames = [
                // 'ProjectIndex', 'ProjectTasks', 
                'MemoryIndex', 'TermIndex', 
                'EngineQuickCreate', 'EngineRagCreate', 'EngineCreate',
                'ToolsAlignIndex', 'ToolsTermExtractionIndex', 'ToolsQAIndex', 'ToolsDetectorIndex', 'ToolsPolishIndex', 
                'ToolsVideoTranslateIndex', 'ToolsTranslationScoreIndex', 
                // 'AgentGptTranslateIndex',
            ];
            if(routeNames.indexOf(route.name) > -1){
                this.showTitle = false;
            }else{
                this.showTitle = true;
                let title = '';
                if(route.meta){
                    title = route.meta.title;
                }
                this.pageTitle = title;
            }
        },
    },
    created(){
        let isLogin = localStorage.getItem('userInfo') !==null && this.$cookies.get('token')!==null;
        if(isLogin){
            this.$store.dispatch('common/getNoticeList');
        }
    },
    mounted(){
        this.initTitle();
    },
    watch:{
        "$route" (to, from){
            this.initTitle();
        },
    }
}
</script>
<style lang="scss" scoped>
.page-header-component {
    height: 100%;
    width: 100%;
    display: flex;
    
    > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-width: 0;
        &.left{
            flex: 1;
            .title {
                font-size: 16px;
                font-weight: 600;
            }
        }
        &.right{
            justify-content: flex-end;
            margin-left: 20px;
        }
        .expand-item{
            height: 26px;
            padding: 0 12px;
            margin-right: 10px;
            background-color: #E9F8F2;
            border-radius: 20px;
            display: flex;
            align-items: center;
            // justify-content: center;
            cursor: pointer;
            .dot-content{
                background-color: #ccf7de;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .dot{
                    background-color: #00d959;
                    width: 7.5px;
                    height: 7.5px;
                    border-radius: 50%;
                }
            }
            .text{
                font-size: 12px;
            }
        }
        .help{
            width: 28px;
            height: 28px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            color: #666666;
            &:hover{
                background-color: #EDEDED;
                color: #000000;
            }
        }
        .avatar-wrapper{
            margin-left: 10px;
            // width: 32px;
            // height: 32px;
            .user-content{
                display: flex;
                justify-content: center;
                cursor: pointer;
                img{
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                }
                .vip-icon{
                    position: absolute;
                    bottom:-1px;
                    right: -2px;
                }
            }
        }
    }
}
@media screen and (max-width: 1440px) {
    .page-header-component {
        .right {
            div.expand-item {
                display: none;
            }
        }
    }
}
</style>
<style lang="scss">
.version-content{
    width: 380px;
    .time{
        margin: 15px 0;
        font-size: 12px;
    }
    .version-title{
        margin-bottom: 20px;
    }
    .new{
        color: #FFFFFF;
        background: #7DD6B3;
        border-radius: 2px;
        padding: 4px;
        font-size: 12px;
    }
    .version{
        font-weight: 600;
        font-size: 15px;
        margin-left: 5px;
    }
    .description{
        font-size: 12px;
    }
}
</style>
